import React from "react"

export default function background() {
  return (
    <div
      className="background"

    ></div>
  )
}
